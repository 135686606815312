var id_affiliatly = 'AF-1050455';
function trackPurchaseWithAffiliatly(orderId, orderTotal, coupon) {
    markPurchase(id_affiliatly, orderId, orderTotal, coupon);
}
function getAffiliatlyCookieData() {
	var data = getCookie('affiliatly_v3');
	var aff_uid = data.match(/&aff_uid=([0-9]+)/);
	if (aff_uid != null) {
		aff_uid = aff_uid[1];
  }

	var id_user = data.match(/&id_user=([0-9]+)/);
	if (id_user != null) {
		id_user = id_user[1];
  }

	var id_token = data.match(/id_token=([A-Za-z0-9]+)/)
	if (id_token != null) {
		id_token = id_token[1];
  }

  var data = {};
  data.aff_uid = aff_uid;
  data.id_user = id_user;
  data.id_token = id_token;
  return data;
}
function getCookie(cname) {
  var name = cname + '=';
  var ca = document
      .cookie
      .split(';');
  for (var i = 0; i < ca.length; i += 1) {
      var c = ca[i].trim();
      if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
      }
  }
  return '';
}